<template>
  <div class="skeleton-profile">
    <div class="skeleton-profile-desktop">
      <div class="skeleton-profile-desktop__info">
        <Skeletor as="div" />

        <Skeletor as="div" />
      </div>

      <div class="skeleton-profile-desktop__rewards">
        <Skeletor as="div" />

        <Skeletor as="div" />
      </div>

      <Skeletor class="skeleton-profile-desktop__tabs" as="div" />

      <Skeletor class="skeleton-profile-desktop__account" as="div" />

      <div class="skeleton-profile-desktop__connections">
        <Skeletor class="skeleton-profile-desktop__connections-title" as="div" />

        <Skeletor class="skeleton-profile-desktop__connections-block" as="div" />
      </div>

      <div v-for="m in GAMES_GROUPS" :key="m" class="recently-played">
        <div class="recently-played-header">
          <Skeletor class="title" as="div" />

          <Skeletor class="show-all" as="div" />
        </div>

        <div class="games">
          <Skeletor v-for="n in GAMES_COUNT" :key="n" class="game" />
        </div>
      </div>
    </div>

    <div class="skeleton-profile-mobile">
      <Skeletor class="skeleton-profile-mobile__info" as="div" />

      <div class="skeleton-profile-mobile__menu">
        <Skeletor v-for="n in MENU_POINTS_COUNT" :key="n" class="skeleton-profile-mobile__menu-item" as="div" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { Skeletor } from 'vue-skeletor';

  const GAMES_GROUPS = 2;
  const GAMES_COUNT = 9;
  const MENU_POINTS_COUNT = 9;
</script>

<style src="~/assets/styles/components/skeleton-preloader/profile-info-page.scss" lang="scss" />
